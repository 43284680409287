var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "tmp-table-pagination" },
    [
      _c(
        "el-table",
        _vm._b(
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loading.isLoad,
                expression: "loading.isLoad",
              },
            ],
            ref: "table_ref",
            attrs: {
              "element-loading-spinner": "el-icon-loading",
              "element-loading-text": _vm.loading.loadText,
              border: _vm.border,
              data: _vm.columData,
              "row-key": _vm.rowKey,
              "max-height": _vm.maxHeight,
            },
            on: { "row-dblclick": _vm.rowDblclick, "row-click": _vm.rowClick },
          },
          "el-table",
          _vm.$attrs,
          false
        ),
        [_vm._t("default")],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }