var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "cmp-table-pagination",
        {
          ref: "cmp_table",
          staticClass: "common-table",
          attrs: {
            "table-data": _vm.tableData,
            pagination: _vm.pagination,
            border: false,
            "hide-on-single-page": false,
            "hide-pagination": true,
            "min-height": false,
          },
          on: { "row-click": _vm.rowClick },
        },
        _vm._l(_vm.tableColumns, function (item) {
          return _c(
            "el-table-column",
            _vm._b(
              {
                key: item.label,
                scopedSlots: _vm._u(
                  [
                    item.isPicture
                      ? {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("div", { staticClass: "url-wrap" }, [
                                _c("img", {
                                  staticClass: "goods-img",
                                  attrs: {
                                    src: scope.row.coverPicture
                                      ? scope.row.coverPicture
                                      : _vm.noImage,
                                    alt: "",
                                  },
                                }),
                                _vm._v(" " + _vm._s(item.title) + " "),
                              ]),
                            ]
                          },
                        }
                      : null,
                  ],
                  null,
                  true
                ),
              },
              "el-table-column",
              item,
              false
            )
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }