var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "order-page wrapper-box" },
    [
      _c("div", { staticClass: "order-table" }, [
        _c("div", { staticClass: "order-table-header" }, [
          _c("span", [_vm._v("收货信息")]),
          _c(
            "span",
            {
              staticClass: "add-address",
              on: {
                click: function ($event) {
                  return _vm.openAddress()
                },
              },
            },
            [_vm._v("新增地址")]
          ),
        ]),
        _c(
          "div",
          { staticClass: "adress-container" },
          [
            _c(
              "el-radio-group",
              {
                model: {
                  value: _vm.radio,
                  callback: function ($$v) {
                    _vm.radio = $$v
                  },
                  expression: "radio",
                },
              },
              _vm._l(_vm.adressList, function (item, index) {
                return _c(
                  "el-radio",
                  { key: index, attrs: { label: item.id } },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          item.consigneeName +
                            " " +
                            item.consigneeMobile +
                            " " +
                            item.consigneeDetailAddress
                        ) +
                        " "
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.addressUpdate(item)
                          },
                        },
                      },
                      [_vm._v("修改")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.deleteAddress(item)
                          },
                        },
                      },
                      [_vm._v("删除")]
                    ),
                  ],
                  1
                )
              }),
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "order-table" },
        [
          _c("div", { staticClass: "order-table-header" }, [
            _vm._v("确认订单"),
          ]),
          _c("common-table", {
            key: "OrderPage",
            attrs: {
              "table-data": _vm.orderData,
              "table-columns": _vm.orderColumns,
            },
            on: { "row-click": _vm.rowClick },
          }),
          _c("div", { staticClass: "order-table-bottom" }, [
            _c(
              "div",
              { staticClass: "order-total" },
              [
                _c("div", { staticClass: "span-item" }, [
                  _c("span", [_vm._v("商品合计 ：")]),
                  _c("span", [
                    _vm._v(
                      "¥" +
                        _vm._s(
                          _vm.getBit(
                            _vm.orderData.selectedTotalAmounts -
                              _vm.orderData.logisticsAmounts
                          )
                        )
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "span-item" }, [
                  _c("span", [_vm._v("邮费 ：")]),
                  _c("span", [
                    _vm._v("¥" + _vm._s(_vm.orderData.logisticsAmounts)),
                  ]),
                ]),
                _c("p", { staticClass: "line" }),
                _c("div", { staticClass: "span-item" }, [
                  _c("span", [_vm._v("应付总额 ：")]),
                  _c("span", { staticClass: "amount" }, [
                    _vm._v("¥" + _vm._s(_vm.orderData.selectedTotalAmounts)),
                  ]),
                ]),
                _c(
                  "el-button",
                  {
                    attrs: { type: "danger" },
                    on: { click: _vm.toOrderCreate },
                  },
                  [_vm._v("去付款")]
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _vm.addressDialog
        ? _c("AddressDialog", {
            attrs: {
              "address-dialog": _vm.addressDialog,
              "dialog-title": _vm.dialogTitle,
              "address-id": _vm.addressId,
              "user-id": _vm.userId,
            },
            on: { closeDialog: _vm.closeDialog, loadAddress: _vm.loadAddress },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }