var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "add-or-edit-dialog" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.dialogTitle,
            visible: _vm.addressDialog,
            width: "470px",
            "show-close": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.addressDialog = $event
            },
            close: _vm.handleClose,
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              attrs: {
                model: _vm.ruleForm,
                rules: _vm.rules,
                "label-position": "left",
                "label-width": "100px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "所在地区", prop: "addressArea" } },
                [
                  _c("el-cascader", {
                    ref: "cascaderAddress",
                    attrs: {
                      options: _vm.cityTree,
                      props: { value: "id", label: "name", emitPath: false },
                    },
                    on: { change: _vm.handleChange },
                    model: {
                      value: _vm.ruleForm.addressArea,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "addressArea", $$v)
                      },
                      expression: "ruleForm.addressArea",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "收货人姓名", prop: "consigneeName" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.ruleForm.consigneeName,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "consigneeName", $$v)
                      },
                      expression: "ruleForm.consigneeName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "电话", prop: "consigneeMobile" } },
                [
                  _c("el-input", {
                    attrs: { maxlength: "11" },
                    model: {
                      value: _vm.ruleForm.consigneeMobile,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "consigneeMobile", _vm._n($$v))
                      },
                      expression: "ruleForm.consigneeMobile",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: { label: "详细地址", prop: "consigneeDetailAddress" },
                },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      maxlength: "150",
                      "show-word-limit": "",
                    },
                    model: {
                      value: _vm.ruleForm.consigneeDetailAddress,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "consigneeDetailAddress", $$v)
                      },
                      expression: "ruleForm.consigneeDetailAddress",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.handleClose } }, [
                _vm._v("关 闭"),
              ]),
              _c(
                "el-button",
                { attrs: { type: "success" }, on: { click: _vm.submitForm } },
                [_vm._v("保 存")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }